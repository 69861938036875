.__pagination__ {
  /* width: 90%; */
  margin: auto;
  /* width: 50%; */
  display: flex;
  justify-content: center;
}

.__page-item__ {
  flex-grow: 1;
  text-align: center;
  font-size: 1rem;
}

.paginate_container {
  display: flex;
  align-items: center;
}
