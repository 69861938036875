.select_container {
  flex-basis: 30%;
  max-width: 30%;
}
@media screen and (max-width: 767px) {
  .select_container {
    flex-basis: 90%;
    max-width: 90%;
    margin-top: 1rem;
  }
}

.searchInputStyle::placeholder {
  font-size: 26px;
}