.notification-box {
  animation: toast-notification 0.3s linear;
}
@keyframes toast-notification {
  from {
    left: -300px;
  }

  to {
    left: 0;
  }
}
