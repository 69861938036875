.container p {
  font-size: 1rem;
}

.container ol li::marker {
  font-weight: bold;
  font-size: 1.2rem;
}

.container ol > li {
  margin-top: 1rem;
}
